import http from '../http-common';
import { APIResponse } from '../types/api-response.interface';
import { AuthResponse, SignInInput } from '../types/auth.interface';
import { PermissionsType } from '../types/permissions.interface';
import { CreateUserResponse } from '../types/user.interface';

const signIn = (data: SignInInput) => {
  return http.post<AuthResponse>('auth/signin', data);
};

// const signup = (data: EmailSignInInput) => {
//     if(data?.mobile) {
//         return http.post<APIResponse>('sign-up', {mobile: data.mobile});
//     }
//     else{
//         return http.post<APIResponse>('sign-up', {email: data?.email});
//     }
// }

const updatePermissions = (permissions: PermissionsType[], adminId: string | undefined) => {
  return http.put<CreateUserResponse>('auth/update-permissions', {adminId, permissions});
}

const signOut = () => {
  return http.get<APIResponse>('auth/sign-out');
};

const AuthService = {
  signIn,
  updatePermissions,
  signOut
};

export default AuthService;
