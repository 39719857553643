import { AlertColor } from '@mui/material';
import { APIResponse } from './api-response.interface';

export interface ToastInfo {
  isOpen: boolean;
  type?: AlertColor;
  message: string;
  timeout?: number | null;
}

export const toastInitialState: ToastInfo = {
  isOpen: false,
  message: '',
  type: 'info',
  timeout: 5000,
};

export enum StatusValues {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  DELETED = 'deleted',
}

export enum LeadStatusValues {
    ACTIVE = 'active',
    DELETED = 'deleted',
    APPROVED = 'approved',
    REJECTED = 'rejected',
}

export interface ImageUploadResponse extends APIResponse {
  data: {
    name: string;
    url: string;
  };
}

export interface PaymentLinkResponse extends APIResponse {
  data: {
      paymentData: any;
  };
}

export interface UniqueFieldResponse extends APIResponse {
  data: { 
    // userId?: string;
    // mentorId?: string;
    // teamId?: string;
    // supportId?: string;
    // partnerId?: string;
    id?: string;
  };
}
