import http from '../http-common';
import { UniqueFieldResponse } from '../types/common.interface';
import { DBModuleNames } from '../types/db-module-names.enum';
import { CreateUserInput, CreateUserResponse, UsersResponse } from '../types/user.interface';

const getAll = (limit: number, page: number) => {
  return http.get<UsersResponse>(`users/getAll/?limit=${limit}&page=${page}`);
};

const create = (data: CreateUserInput) => {
  return http.post<CreateUserResponse>('users/create', data);
};

const getById = (id: string | undefined) => {
  return http.get<CreateUserResponse>(`users/getById/?id=${id}`);
};

const update = (data: CreateUserInput) => {
  return http.put<CreateUserResponse>('users/update', data);
};

const deleteUser = (id: string) => {
  return http.put<CreateUserResponse>('users/delete', { id });
};

const searchUser = (input: string) => {
  return http.get<UsersResponse>(`users/search/?user=${input}`);
};

const checkUniqueField = (key: string, value: string, id?: string) => {
  if (key === 'email') {
    return http.post<UniqueFieldResponse>('users/checkUniqueField', { email: value, moduleName: DBModuleNames.USERS, id: id });
  } else if (key === 'username') {
    return http.post<UniqueFieldResponse>('users/checkUniqueField', {
      username: value,
      moduleName: DBModuleNames.USERS,
      id: id,
    });
  } else {
    return http.post<UniqueFieldResponse>('users/checkUniqueField', {
      mobileNumber: value,
      moduleName: DBModuleNames.USERS,
      id: id,
    });
  }
};

const UserService = {
  getAll,
  create,
  update,
  getById,
  deleteUser,
  searchUser,
  checkUniqueField,
};

export default UserService;
