import { Reducer } from '@reduxjs/toolkit';
import { User } from '../../types/auth.interface';
import { AuthActionTypes, AuthActions } from '../actions/auth.actions';

export interface AuthState {
  userInfo: {
    user: User | null,
    accessToken: string;
  };
}

const initialState: AuthState = {
  userInfo: {
    user: null,
    accessToken: '',
  },
};

export const authReducer: Reducer<AuthState, any> = (
  state: AuthState = initialState,
  action: AuthActions
): AuthState => {
  switch (action.type) {
    case AuthActionTypes.SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case AuthActionTypes.SET_UPDATED_USER:
      return {
        ...state,
        userInfo: {...state.userInfo, user: action.payload},
      };
    default:
      return state;
  }
};
