export enum PermissionScope {
  CREATE = 'create',
  READ = 'read',
  UPDATE = 'update',
  DELETE = 'delete',
}

export enum ModuleNames {
  TESTIMONIALS = 'testimonials',
  STUDENTS = 'students',
  MENTORS = 'mentors',
  USERS = 'users',
  LEADS = 'leads',
  PERMISSIONS = 'permissions',
  DASHBOARD = 'dashboard',
  TICKER = 'ticker',
  BACKED_BY = 'backed-by',
  TEAM = 'team',
  SUPPORT = 'support',
  PARTNERS = 'partners',
  MENTOR_LEADS = 'mentor-leads',
  SALES_LEADS = 'sales-leads',
  PROGRAMS = 'programs',
  COURSES = 'courses',
  FAQS = 'faqs',
  NEWS_LETTERS = 'newsletter',
  EMPLOYERS = 'employers',
  PLANS = 'plans',
  JOBS = 'jobs',
  COMPLAINTS = 'complaints',
}
