import { APIResponse } from "./api-response.interface";
import { PermissionsType } from "./permissions.interface";

export interface Address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  landmark: string;
}

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  DELETED = 'deleted',
}

export interface User {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  mobileNumber: string;
  dob: Date;
  address: Address;
  role: string;
  permissions: PermissionsType[];
  createdAt: Date;
  status: UserStatus;
  img?: string;
  _id?: string;
}

export interface SignInInput {
  email: string;
  password: string;
}

export interface SignInResponse {
  user: User | null;
  accessToken: string;
}

export interface AuthResponse extends APIResponse {
  data: {
    user: User | null;
    accessToken: string;
  }
}
