import http from '../http-common';
import { ImageUploadResponse, PaymentLinkResponse } from '../types/common.interface';
import { PaymentLinkBody } from '../types/payment-link.interface';

const config = {
    headers: {
        "Content-Type": "multipart/form-data; boundary=imageUpload"
    }
};

const uploadImage = (data: File) => {
  return http.post<ImageUploadResponse>('common/mediaUpload', {img: data}, config);
};

const generatePaymentLink = (data: PaymentLinkBody) => {
  return http.post<PaymentLinkResponse>('common/paymentLink', data);
};

const CommonService = {
  uploadImage,
  generatePaymentLink
};

export default CommonService;
