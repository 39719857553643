import { CloudUpload } from '@mui/icons-material';
import { Avatar, Box, Button } from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface ImageUploadProps {
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  image?: string;
  isUpdate?: boolean;
  isRequired?: boolean;
  resetImage?: boolean;
}

const ImageUpload: FC<ImageUploadProps> = ({ onFileChange, image, isUpdate = false, isRequired, resetImage }) => {
  const [imagePreview, setImagePreview] = useState('');

  useEffect(() => {
    if (resetImage) {
      setImagePreview('');
    }
  }, [resetImage]);
  return (
    <Box display="flex" textAlign="center" alignItems={'center'}>
      <Avatar
        sx={{
          width: 60,
          height: 60,
          background: 'black',
          '& img': { objectFit: 'initial', textIndent: 'initial', height: 'initial', maxHeight: '60px' },
        }}
        src={(imagePreview || image)}
      />

      <Button variant="contained" component="label" startIcon={<CloudUpload />} sx={{ marginLeft: '10px' }}>
        {isUpdate ? 'Update' : 'Select'} Image {isRequired && '*'}
        <input 
          // name="avatar"
          accept="image/*"
          id="contained-button-file"
          type="file"
          hidden
          onChange={(event) => {
            onFileChange(event);
            const fileReader = new FileReader();
            fileReader.onload = () => {
              if (fileReader.readyState === 2) {
                setImagePreview(fileReader.result as string);
              }
            };
            if (event.target.files) fileReader.readAsDataURL(event.target.files[0]);
          }}
        />
      </Button>
    </Box>
  );
};

export default ImageUpload;
