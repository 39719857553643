import { Reducer } from '@reduxjs/toolkit';
import { CommonActionTypes, CommonActions } from '../actions/common.actions';
import { ToastInfo, toastInitialState } from '../../types/common.interface';

export interface CommonState {
  isSidebarOpen: boolean;
  toastInfo: ToastInfo;
}

const initialState: CommonState = {
  isSidebarOpen: true,
  toastInfo: toastInitialState,
};

export const commonReducer: Reducer<CommonState, any> = (
  state: CommonState = initialState,
  action: CommonActions
): CommonState => {
  switch (action.type) {
    case CommonActionTypes.IS_SIDEBAR_OPEN:
      return {
        ...state,
        isSidebarOpen: action.payload,
      };
    case CommonActionTypes.SHOW_TOAST:
      return {
        ...state,
        toastInfo: action.payload,
      };
    case CommonActionTypes.REMOVE_TOAST:
      return {
        ...state,
        toastInfo: action.payload,
      };
    default:
      return state;
  }
};
