export enum DBModuleNames {
  TESTIMONIALS = 'Testimonial',
  STUDENTS = 'Student',
  MENTORS = 'Mentor',
  USERS = 'User',
  LEADS = 'Lead',
  TEAM = 'Team',
  SUPPORT = 'Support',
  PARTNERS = 'Partner',
  NEWSLETTERS = 'NewsLetter'
};