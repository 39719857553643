import { find, includes } from 'lodash';
import { ModuleNames, PermissionScope } from '../types/permissions.enum';
import { PermissionsType } from '../types/permissions.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

export const usePermissions = (moduleName: ModuleNames) => {
  const permissions = useSelector((state: RootState) =>
    state.authState.userInfo?.user?.permissions ? state.authState.userInfo?.user?.permissions : []
  );
  if (moduleName === ModuleNames.DASHBOARD)
    return {
      canView: true,
      canCreate: false,
      canUpdate: false,
      canDelete: false,
    };
  const modulePermissions: PermissionsType | undefined = find(
    permissions,
    (permission: PermissionsType) => permission.module === moduleName
  );
  if (!modulePermissions)
    return {
      canView: false,
      canCreate: false,
      canUpdate: false,
      canDelete: false,
    };
  const hasPermissionScope = (permission: PermissionScope) => {
    return includes(modulePermissions.permission, permission);
  };
  return {
    canView: hasPermissionScope(PermissionScope.READ),
    canCreate: hasPermissionScope(PermissionScope.CREATE),
    canUpdate: hasPermissionScope(PermissionScope.UPDATE),
    canDelete: hasPermissionScope(PermissionScope.DELETE),
  };
};
