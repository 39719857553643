import { Box } from '@mui/material';
import { Suspense, useEffect, lazy } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import AppHeader from '../components/common/header';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import SideNav from '../components/common/sidenav';
import UserProfile from '../components/common/profile';

const Dashboard = lazy(() => import('../components/main/dashboard'));
const Testimonials = lazy(() => import('../components/main/testimonials'));
const CreateTestimonial = lazy(() => import('../components/main/testimonials/create'));
const PermissionsView = lazy(() => import('../components/main/permissions'));
const Users = lazy(() => import('../components/main/users'));
const CreateUser = lazy(() => import('../components/main/users/create'));
const Mentors = lazy(() => import('../components/main/mentors'));
const Team = lazy(() => import('../components/main/team'));
const BackedBy = lazy(() => import('../components/main/backed-by'));
const Partners = lazy(() => import('../components/main/partners'));
const Ticker = lazy(() => import('../components/main/ticker'));
const MentorLeads = lazy(() => import('../components/main/mentor-leads'));
const SalesLeads = lazy(() => import('../components/main/sales-leads'));
const Courses = lazy(() => import('../components/main/courses'));
const Programs = lazy(() => import('../components/main/programs'));
const Faqs = lazy(() => import('../components/main/faqs'));
const NewsLetters = lazy(() => import('../components/main/news-letters'));
const Employers = lazy(() => import('../components/main/employer'));
const Students = lazy(() => import('../components/main/student'));
const Plans = lazy(() => import('../components/main/plan'));
const Jobs = lazy(() => import('../components/main/jobs'));
const Complaints = lazy(() => import('../components/main/complaints'));

const Main = () => { 
  // const { userInfo } = useSelector((state: RootState) => state.authState);
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo') || '{}');
  const { isSidebarOpen } = useSelector((state: RootState) => state.commonState);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo.accessToken) {
      navigate('/auth/signin');
    }
  }, [userInfo]);

  return (
    <Box overflow={'hidden'}>
      <Box sx={{ zIndex: 2, position: 'relative' }}>
        <AppHeader />
      </Box>
      <Box display={'flex'} sx={{ background: '#fafafb' }}>
        <Box width={isSidebarOpen ? '250px' : '55px'} sx={{ transition: 'all .3s', overflow: 'hidden' }}>
          <SideNav isSidebarOpen={isSidebarOpen} />
        </Box>
        <Box
          p={{ sm: 3, xs: 2 }}
          flexGrow={1}
          width={'calc(100% - 250px)'}
          height={'calc(100vh - 75px)'}
          overflow={'hidden auto'}
        >
          <Box>
            <Suspense fallback={'Loading...'}>
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/testimonials" element={<Testimonials />} />
                <Route path="/testimonials/create" element={<CreateTestimonial />} />
                <Route path="/testimonials/update/:id" element={<CreateTestimonial />} />
                <Route path="/permissions" element={<PermissionsView />} />
                <Route path="/users" element={<Users />} />
                <Route path="/users/create" element={<CreateUser />} />
                <Route path="/users/update/:id" element={<CreateUser />} />
                <Route path="/mentors/*" element={<Mentors />} />
                <Route path="/team/*" element={<Team />} />
                <Route path="/backed-by/*" element={<BackedBy />} />
                <Route path="/partners/*" element={<Partners />} />
                <Route path="/ticker/*" element={<Ticker />} />
                <Route path="/mentor-leads/*" element={<MentorLeads />} />
                <Route path="/sales-leads/*" element={<SalesLeads />} />
                <Route path="/courses/*" element={<Courses />} />
                <Route path="/programs/*" element={<Programs />} />
                <Route path="/faqs/*" element={<Faqs />} />
                <Route path="/newsletter/*" element={<NewsLetters />} />
                <Route path="/profile" element={<UserProfile user={userInfo?.user} />} />
                <Route path="/plans/*" element={<Plans />} />
                <Route path="/jobs/*" element={<Jobs />} />
                <Route path="/complaints/*" element={<Complaints />} />
                {/* Employer Routes */}
                <Route path="/employers/*" element={<Employers />} />
                <Route path="/students/*" element={<Students />} />
              </Routes>
            </Suspense>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Main;
