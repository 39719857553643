import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { Suspense } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import LoginBg from '../assets/images/login-bg.jpg';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

const Login = React.lazy(() => import('../components/auth/login'));
const Signup = React.lazy(() => import('../components/auth/signup'));

const Auth = () => {
  const { userInfo } = useSelector((state: RootState) => state.authState);
  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo.accessToken) {
      navigate('/dashboard');
    }
  }, [userInfo]);

  return (
    <Box
      sx={{
        background: `url(${LoginBg}) no-repeat fixed center center / cover`,
      }}
      height={'100vh'}
      width={'100vw'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Suspense fallback={'Loading...'}>
        <Routes>
          <Route path="signin" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="" element={<Navigate replace to="/auth/signin" />} />
        </Routes>
      </Suspense>
    </Box>
  );
};

export default Auth;
