import { Suspense, useEffect } from 'react';
import './App.scss';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Main from './views/main';
import Auth from './views/auth';
import { ThemeProvider } from '@mui/material';
import { getLightTheme } from './theme';
import Toast from './components/common/toast';
import { useDispatch } from 'react-redux';
import { AuthActionTypes, AuthActions } from './store/actions/auth.actions';
import { setAuthToken } from './utils/set-token';

function App() {
  const userInfo = JSON.parse(sessionStorage.getItem('userInfo') || '{}');
  const dispatch = useDispatch();
  // if (process.env.NODE_ENV == 'production') {
  //   console.log(process.env.REACT_APP_DEV_API_BASE_URL); 
  // } else {
  //   console.log(process.env.REACT_APP_LOCAL_API_BASE_URL); 
  // }

  useEffect(() => {
    if (userInfo?.accessToken) {
      setAuthToken(userInfo.accessToken);
      dispatch<AuthActions>({
        type: AuthActionTypes.SET_USER_INFO,
        payload: userInfo,
      });
    }
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={getLightTheme()}>
        <Toast />
        <Suspense fallback={'Loading...'}>
          <Routes>
            <Route path="/*" element={<Main />} />
            <Route path="/auth/*" element={<Auth />} />
            <Route path="" element={<Navigate replace to="/auth/signin" />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
