import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Link, useParams } from 'react-router-dom';
import { FC, useState } from 'react';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useToast } from '../../../hooks/use-toast';
import { CreateUserInput } from '../../../types/user.interface';
import CommonService from '../../../services/common.service';
import UserService from '../../../services/user.service';
import { User, UserStatus } from '../../../types/auth.interface';
import ImageUpload from '../image-upload';

const validationSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string().required('First Name is required').max(50).min(3),
    lastName: Yup.string().max(50).min(3),
    email: Yup.string().email().required('Email is required').max(50).min(3),
    username: Yup.string().required('Username is required').max(50).min(3),
    mobileNumber: Yup.string().required('Mobile Number is required').max(10).min(10),
    address: Yup.object().shape({
      address1: Yup.string().required().max(70).min(4),
      address2: Yup.string().max(70).min(4),
      city: Yup.string().required().max(30).min(3),
      state: Yup.string().required().max(30).min(3),
      country: Yup.string().required().max(30).min(3),
      pincode: Yup.string().required().max(6).min(6),
      landmark: Yup.string(),
    }),
    dob: Yup.string().max(30),
    role: Yup.string(),
    status: Yup.string(),
    img: Yup.string(),
  });
};

interface UserProfileProps {
  user: User | null;
}

const UserProfile: FC<UserProfileProps> = ({ user }) => {
  const { id } = useParams();
  const { displayToast } = useToast();
  const [userImage, setUserImage] = useState<File>();

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files) setUserImage(event.target.files[0]);
  };
  const update = async (values: CreateUserInput, actions?: FormikHelpers<CreateUserInput>) => {
    let formValues = values;
    try {
      if (userImage) {
        const imageResponse = await CommonService.uploadImage(userImage);
        if (imageResponse.data?.data?.url) {
          formValues = { ...formValues, img: imageResponse.data.data.url };
        }
        if (imageResponse.data?.error) {
          displayToast({ isOpen: true, message: imageResponse?.data?.message, type: 'error' });
          return;
        }
      }
      const userResponse = await UserService.update({ ...formValues, _id: user?._id });
      if (userResponse.data?.data && userResponse.data?.data.user) {
        displayToast({ isOpen: true, message: userResponse.data.message });
      }
    } catch (error: any) {
      displayToast({ isOpen: true, message: error?.response?.data?.message, type: 'error' });
    }
  };

  const initialValues: CreateUserInput = {
    firstName: user?.firstName ? user.firstName : '',
    lastName: user?.lastName ? user.lastName : '',
    email: user?.email ? user.email : '',
    username: user?.username ? user.username : '',
    mobileNumber: user?.mobileNumber ? user.mobileNumber : '',
    address: {
      address1: user?.address.address1 ? user?.address.address1 : '',
      address2: user?.address.address2 ? user?.address.address2 : '',
      city: user?.address.city ? user?.address.city : '',
      state: user?.address.state ? user?.address.state : '',
      country: user?.address.country ? user?.address.country : '',
      pincode: user?.address.pincode ? user?.address.pincode : '',
      landmark: user?.address.landmark ? user?.address.landmark : '',
    },
    role: user?.role ? user.role : '',
    dob: user?.dob ? user.dob : undefined,
    status: user?.status ? user.status : UserStatus.ACTIVE,
    img: user?.img ? user.img : '',
  };

  return (
    <Box>
      <Paper elevation={1}>
        <Box p={3}>
          <Box
            borderBottom={'1px solid #eaeaeb'}
            pb={2}
            mb={3}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant="h3" color={'primary.main'}>
              {id ? 'Update' : 'Create'} User
            </Typography>
            <Link to="/users">
              <Button
                size="medium"
                sx={{ width: '150px', marginLeft: '8px' }}
                fullWidth
                variant="contained"
                color="inherit"
              >
                Back
              </Button>
            </Link>
          </Box>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={async (values, actions) => {
              update(values, actions);
            }}
            validationSchema={validationSchema}
          >
            {(formik) => {
              return (
                <>
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xl={4} lg={4} sm={6} xs={12}>
                        <Box mb={1}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: 500 }}>First Name*</FormLabel>
                            <TextField
                              size="small"
                              name="firstName"
                              placeholder="First Name"
                              value={formik.values.firstName}
                              onChange={formik.handleChange}
                              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                              helperText={formik.touched.firstName && formik.errors.firstName}
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xl={4} lg={4} sm={6} xs={12}>
                        <Box mb={1}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: 500 }}>Last Name</FormLabel>
                            <TextField
                              size="small"
                              name="lastName"
                              placeholder="Last Name"
                              value={formik.values.lastName}
                              onChange={formik.handleChange}
                              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                              helperText={formik.touched.lastName && formik.errors.lastName}
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xl={4} lg={4} sm={6} xs={12}>
                        <Box mb={1}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: 500 }}>Email*</FormLabel>
                            <TextField
                              size="small"
                              name="email"
                              placeholder="Email"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              error={formik.touched.email && Boolean(formik.errors.email)}
                              helperText={formik.touched.email && formik.errors.email}
                              disabled={true}
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item sm={12} xs={12}>
                        <Box mb={1}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: 500 }}>Username*</FormLabel>
                            <TextField
                              size="small"
                              name="username"
                              placeholder="Username"
                              value={formik.values.username}
                              onChange={formik.handleChange}
                              error={formik.touched.username && Boolean(formik.errors.username)}
                              helperText={formik.touched.username && formik.errors.username}
                              disabled={true}
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xl={4} lg={4} sm={6} xs={12}>
                        <Box mb={1}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: 500 }}>Role*</FormLabel>
                            <Select
                              size="small"
                              name="role"
                              placeholder="Role"
                              value={formik.values.role}
                              onChange={formik.handleChange}
                              error={formik.touched.role && Boolean(formik.errors.role)}
                              sx={{ borderRadius: '10px' }}
                              disabled={true}
                            >
                              <MenuItem value={'admin'}>Admin</MenuItem>
                              <MenuItem value={'superadmin'}>Super Admin</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xl={4} lg={4} sm={6} xs={12}>
                        <Box mb={1}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: 500 }}>Mobile Number*</FormLabel>
                            <TextField
                              size="small"
                              name="mobileNumber"
                              placeholder="Mobile Number"
                              value={formik.values.mobileNumber}
                              onChange={formik.handleChange}
                              error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
                              helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
                              disabled={true}
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xl={4} lg={4} sm={6} xs={12}>
                        <Box mb={1}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: 500 }}>DoB</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateField
                                value={formik.values.dob ? dayjs(formik.values.dob) : ' '}
                                onChange={(newValue) => formik.setFieldValue('dob', newValue)}
                                format="MM-DD-YYYY"
                                helperText={formik.touched.dob && formik.errors.dob}
                                size="small"
                              />
                            </LocalizationProvider>
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xl={4} lg={4} sm={6} xs={12}>
                        <Box mb={1}>
                          <ImageUpload image={formik.values.img} onFileChange={onFileChange} isUpdate={Boolean(id)} />
                        </Box>
                      </Grid>

                      <Grid item xl={12} lg={12} sm={12} xs={12}>
                        <Box>Address*</Box>
                      </Grid>

                      <Grid item xl={12} lg={12} sm={12} xs={12}>
                        <Box mb={1}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: 500 }}>Address Line 1*</FormLabel>
                            <TextField
                              size="small"
                              name="address.address1"
                              placeholder="Address Line 1"
                              value={formik.values.address.address1}
                              onChange={formik.handleChange}
                              error={formik.touched.address?.address1 && Boolean(formik.errors.address?.address1)}
                              helperText={formik.touched.address?.address1 && formik.errors.address?.address1}
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xl={12} lg={12} sm={12} xs={12}>
                        <Box mb={1}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: 500 }}>Address Line 2</FormLabel>
                            <TextField
                              size="small"
                              name="address.address2"
                              placeholder="Address Line 2"
                              value={formik.values.address.address2}
                              onChange={formik.handleChange}
                              error={formik.touched.address?.address2 && Boolean(formik.errors.address?.address2)}
                              helperText={formik.touched.address?.address2 && formik.errors.address?.address2}
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xl={4} lg={4} sm={6} xs={12}>
                        <Box mb={1}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: 500 }}>City*</FormLabel>
                            <TextField
                              size="small"
                              name="address.city"
                              placeholder="City"
                              value={formik.values.address?.city}
                              onChange={formik.handleChange}
                              error={formik.touched.address?.city && Boolean(formik.errors.address?.city)}
                              helperText={formik.touched.address?.city && formik.errors.address?.city}
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xl={4} lg={4} sm={6} xs={12}>
                        <Box mb={1}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: 500 }}>State*</FormLabel>
                            <TextField
                              size="small"
                              name="address.state"
                              placeholder="State"
                              value={formik.values.address?.state}
                              onChange={formik.handleChange}
                              error={formik.touched.address?.state && Boolean(formik.errors.address?.state)}
                              helperText={formik.touched.address?.state && formik.errors.address?.state}
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xl={4} lg={4} sm={6} xs={12}>
                        <Box mb={1}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: 500 }}>Country*</FormLabel>
                            <TextField
                              size="small"
                              name="address.country"
                              placeholder="Country"
                              value={formik.values.address.country}
                              onChange={formik.handleChange}
                              error={formik.touched.address?.country && Boolean(formik.errors.address?.country)}
                              helperText={formik.touched.address?.country && formik.errors.address?.country}
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xl={4} lg={4} sm={4} xs={12}>
                        <Box mb={1}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: 500 }}>Pincode*</FormLabel>
                            <TextField
                              size="small"
                              name="address.pincode"
                              placeholder="Pincode"
                              value={formik.values.address?.pincode}
                              onChange={formik.handleChange}
                              error={formik.touched.address?.pincode && Boolean(formik.errors.address?.pincode)}
                              helperText={formik.touched.address?.pincode && formik.errors.address?.pincode}
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xl={8} lg={8} sm={8} xs={12}>
                        <Box mb={1}>
                          <FormControl fullWidth>
                            <FormLabel sx={{ fontWeight: 500 }}>Landmark</FormLabel>
                            <TextField
                              size="small"
                              name="address.landmark"
                              placeholder="Landmark"
                              value={formik.values.address?.landmark}
                              onChange={formik.handleChange}
                              error={formik.touched.address?.landmark && Boolean(formik.errors.address?.landmark)}
                              helperText={formik.touched.address?.landmark && formik.errors.address?.landmark}
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>

                    <Box pt={2} mt={2} borderTop={'1px solid #eaeaeb'} textAlign={'right'}>
                      <Button
                        size="medium"
                        sx={{ width: '150px' }}
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={formik.submitForm}
                        disabled={!formik.isValid}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Box>
      </Paper>
    </Box>
  );
};

export default UserProfile;
