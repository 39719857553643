import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Alert, Snackbar } from '@mui/material';
import { useToast } from '../../../hooks/use-toast';

const Toast = () => {
  const { toastInfo } = useSelector((state: RootState) => state.commonState);
  const { clearToast } = useToast();

  return (
    <Snackbar
      open={toastInfo.isOpen}
      autoHideDuration={toastInfo?.timeout ? toastInfo.timeout : 5000}
      onClose={clearToast}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      sx={{maxWidth: {sm: '450px', xs: '90%'}}}
    >
      <Alert variant="filled" onClose={clearToast} severity={toastInfo?.type ? toastInfo.type : 'success'}>
        {toastInfo.message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
