import axiosInstance from '../http-common';

export const setAuthToken = (token: string) => {
  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } else {
    axiosInstance.defaults.headers.common['Authorization'] = '';
  }

  // console.log('tokken: ', token);

  // if (token) {
  //   axiosInstance.interceptors.request.use(function (config) {
  //     config.headers.Authorization = token;
  //     return config;
  //   });
  // }
};
