import { ToastInfo } from '../../types/common.interface';

export enum CommonActionTypes {
  IS_SIDEBAR_OPEN = 'IS_SIDEBAR_OPEN',
  SHOW_TOAST = 'SHOW_TOAST',
  REMOVE_TOAST = 'REMOVE_TOAST',
}

interface SidebarToggleAction {
  type: CommonActionTypes.IS_SIDEBAR_OPEN;
  payload: boolean;
}

interface ShowAlertAction {
  type: CommonActionTypes.SHOW_TOAST;
  payload: ToastInfo;
}

interface RemoveAlertAction {
  type: CommonActionTypes.REMOVE_TOAST;
  payload: ToastInfo;
}

export type CommonActions = SidebarToggleAction | ShowAlertAction | RemoveAlertAction;
