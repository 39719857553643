import axios from 'axios';

const http = axios.create({
  // baseURL: 'http://localhost:3300/',
  // baseURL: 'https://devapi.thedrivesales.com:3300/',
  baseURL: 'https://api.thedrivesales.com:3300/',
  headers: {
    'Content-type': 'application/json',
  },
  withCredentials: true
});

http.interceptors.response.use(response => response, error => {
  if (error.response.status === 401){
    console.log('Status 401 received, logging out!!!!');
    sessionStorage.removeItem('userInfo');
    sessionStorage.removeItem('onboarding');
    window.location.href = '/auth/signin';
  }
  return Promise.reject(error);
});

export default http;
